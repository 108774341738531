import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import ImagesWithText from "components/shared/ImagesWithText"
import SEO from "utils/Seo"

import image1 from "images/home/2.jpg"
import image2 from "images/home/4.jpg"


const ArticlesPage = () => (
    <Layout>
        <SEO title="Articles | Landscaper In Devon | Garden Design In Cornwall" description="If you would like to learn more about Plantlife Landscapes, our articles are a great resource & the perfect place to start! Learn more about what we do, how we do it and, most importantly, why we do it." />
        <MaxContainer>
            <ImagesWithText
                image1={{ src: image1, alt: "landscape garden path" }}
                image2={{ src: image2, alt: "landscape gardening" }}
            >
                <h1>Articles</h1>
                <Row>
                    <Col xs={12} lg={12}>
                        <p><a href="/landscape-gardener-plymouth/">Landscape Gardener Plymouth</a> - 24th July 2023</p>
                        <p><a href="/garden-design-in-tavistock/">Garden Design In Tavistock</a> - 29th May 2023</p>
                    </Col>
                </Row>
            </ImagesWithText>
        </MaxContainer>
    </Layout>
)

export default ArticlesPage
